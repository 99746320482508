.animal-tile {
  cursor: pointer;
  height: calc(108px + 1.5vw);
  width: calc(108px + 1.5vw);
  box-shadow: 0 0 4px hsla(210, 100%, 75%, .3);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid hsla(210, 100%, 75%, .3);
  position: relative;
}

.animal-tile.matched {
  pointer-events: none;
}

.animal-name {
  font-family: Poppins, sans-serif;
  font-size: 1.25rem;
  position: absolute;
  top: 55%;
  color: #252525;
  z-index: 1;
}

/* Tile Background Colors */
.blue { background-color: hsla(174, 72%, 56%, 0.5); }   
.green { background-color: hsla(115, 50%, 45%, 0.5); } 
.pink {background-color: hsla(325, 100%, 50%, 0.5); }
.yellow { background-color: hsla(55, 100%, 50%, 0.5); }   

