* {
  box-sizing: border-box;
}

.app {
  min-height: 100vh;
  background-color: hsl(0, 0%, 95%);
  color: hsl(330, 3%, 13%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

h1.title {
  text-align: center;
  margin: 0.25em auto;
  font-family: Poppins, sans-serif;
  font-size: calc(3rem + 1vw);
  font-weight: 700;
  text-shadow: 0 0 1px #252525;
}

.wrapper {
  min-height: 600px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 4px;
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.tile-container {
  margin-bottom: 0.5em;
  display: grid;
  grid-template: auto auto / repeat(3, 1fr);
  gap: 12px;
  justify-items: center;
}

.button-container {
  margin-top: 1em;
  width: 360px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.btn {
  height: 60px;
  width: 110px;
  border: none;
  font-family: Poppins, sans-serif;
  font-size: calc(1rem + 0.2vw);
  font-weight: 700;
  border-radius: 4px;
  background-color: hsla(210, 100%, 75%, .5);
  color: hsl(330, 3%, 13%);
  text-shadow: 0 0 1px #FFFDFA;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  transition: opacity 0.5s ease-out;
}

.btn:active {
  box-shadow: inset 2px 2px 10px -3px hsl(210, 100%, 75%);
}

.btn[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

@keyframes winFade {
  0% { opacity: 1; }
  50% { opacity: 0.2; }
  100% { opacity: 1; }
}

.win-animation {
  animation: winFade 2s ease-in-out;
}

@media (min-width: 768px) {
  .wrapper {
    height: 700px;
  }
}

